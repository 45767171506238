import tw, { styled } from 'twin.macro'
export { SContainer, SExpandedNav, SDrawer, SCrossDrawerDiv, SMenuIconDiv, SHeaderInner, SBookNowConatiner }

const SContainer = styled.div`
${tw`text-xl md:text-5xl bg-white flex px-2 py-2 justify-between shadow-[1px 1px 3px 0px #747474] flex items-center fixed w-full z-20`}
    img{
        ${tw`w-28 `}
    }

    svg {
        ${tw`text-5xl cursor-pointer`}
    }
    
   
`

const SExpandedNav = styled.div`
${tw`hidden lg:flex items-center `}
   
`

const SDrawer = styled.div`
${tw`w-60 pt-5 px-2 h-full space-y-5 bg-white border-2 border-primary rounded-l-3xl`}
`
const SCrossDrawerDiv = styled.div`
    ${tw`flex justify-end `}

     svg {
        ${tw`text-5xl cursor-pointer`}
    }
`
const SMenuIconDiv = styled.div`
${tw`lg:hidden `}`

const SHeaderInner = styled.div`
${tw`border-r-2 border-gray-300 h-14  flex items-center px-2`}
 :nth-last-child(-n+2){
        ${tw`border-r-0`}
    }

`

const SBookNowConatiner = styled.div`
${tw`bg-primary shadow-[0px 0px 1px 5px #E1CA46] cursor-pointer text-white rounded-full w-24 h-24  flex justify-center items-center`}`