import tw, { styled } from 'twin.macro'
export { CSheading, CSsubHeading, CSblackFooter, CSBlackNormal, CSImageDesc, CSh1, CSh4, CSHeaderTitles, CSblackHeadingFooter, CSh2, CSh3, CSWhiteHeadingFooter }

const CSheading = styled.div`
${tw`text-xl mx-2 font-semibold my-1`}
   
`
const CSsubHeading = styled.div`
${tw`text-sm font-medium`}
   
`
const CSblackFooter = styled.div`
${tw`text-lg  text-gray-900 font-medium cursor-pointer text-left `}
   
`
const CSBlackNormal = styled.div`
${tw`text-sm text-black font-medium`}
   
`
const CSImageDesc = styled.div`
${tw`text-base text-gray-800 font-medium text-center w-[95%] mx-auto mb-10`}`

const CSh1 = styled.div`
${tw`text-4xl text-black font-bold font-medium`}`

const CSh4 = styled.div`
${tw`text-sm text-black font-light `}`
const CSHeaderTitles = styled.div`
${tw`text-base text-gray-700 font-semibold`}`
const CSblackHeadingFooter = styled.div`
${tw`text-2xl text-black font-bold text-left`} `

const CSh2 = styled.div`
${tw`text-xl text-black font-bold `}`

const CSh3 = styled.div`
${tw`text-base text-black  font-medium`}`
const CSWhiteHeadingFooter = styled.div`
${tw`text-2xl text-white font-bold text-left`} `