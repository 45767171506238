import { useRouter } from 'next/router'
import React from 'react'
import { CSHeaderTitles } from "@styles/common";
function DropDown({ data, title }) {
    const router = useRouter()
    return (
        <div className="dropdown cursor-pointer">
            <CSHeaderTitles className='mx-6'>{title}
            </CSHeaderTitles>
            <div className="dropdown-content bg-tableRow">
                {data.map(([title, link]) => (
                    <CSHeaderTitles className='mt-2' onClick={() => { router.push(link) }}>{title}</CSHeaderTitles>
                ))}
            </div>
        </div>
    )
}

export default DropDown