
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import Layout from '../components/wrapper/Layout'
import '../styles/globals.css'
import axios from 'axios';
import FeedbackWrapper from '@components/wrapper/FeedbackWrapper';

function MyApp({ Component, pageProps }) {
  axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL
  return <>
    <FeedbackWrapper>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </FeedbackWrapper>
  </>
}

export default MyApp
