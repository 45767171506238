import { CSblackHeadingFooter, CSBlackNormal, CSblackFooter } from '@styles/common'
import { SContainer, SInner, SBottom } from '@styles/footerStyle'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react'
import { useRouter } from 'next/router';

function Footer() {
    const router = useRouter();
    return (
        <SContainer>
            <SInner>
                <div className='mt-3 flex flex-col lg:justify-start lg:items-start justify-center items-center'>
                    <img src='/assets/images/logo.png' />
                    <CSblackFooter className='text-center sm:text-left mt-10'>
                        Suite 2 The Beehive<br /> Lions Drive Blackburn BB1 2QS
                    </CSblackFooter>
                </div>
                <div className='grid grid-cols-2 gap-10 sm:grid-cols-4 sm:gap-20 mt-3 text-center m-auto'>
                    <div className='text-center'>
                        <CSblackHeadingFooter>Our Coverage</CSblackHeadingFooter>
                        <CSblackFooter onClick={() => { router.push('/privacyPolicy') }}>Privacy Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/cookiesPolicy') }}>Cookies Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/terms-and-cond') }}>Terms & Conditions</CSblackFooter>

                    </div>
                    <div>
                        <CSblackHeadingFooter>Services</CSblackHeadingFooter>
                        <CSblackFooter onClick={() => { router.push('/privacyPolicy') }}>Privacy Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/cookiesPolicy') }}>Cookies Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/terms-and-cond') }}>Terms & Conditions</CSblackFooter>

                    </div>
                    <div>
                        <CSblackHeadingFooter>Latest News</CSblackHeadingFooter>
                        <CSblackFooter onClick={() => { router.push('/privacyPolicy') }}>Privacy Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/cookiesPolicy') }}>Cookies Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/terms-and-cond') }}>Terms & Conditions</CSblackFooter>


                    </div>
                    <div>
                        <CSblackHeadingFooter>Support</CSblackHeadingFooter>
                        <CSblackFooter onClick={() => { router.push('/contact-us') }}>Contact Us</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/privacyPolicy') }}>Privacy Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/cookiesPolicy') }}>Cookies Policy</CSblackFooter>
                        <CSblackFooter onClick={() => { router.push('/terms-and-cond') }}>Terms & Conditions</CSblackFooter>

                    </div>
                </div>
                <div className='grid grid-cols-1 gap-2 h-14 mt-5 '>
                    <CSblackFooter className='text-center'>Get in touch</CSblackFooter>
                    <div className='gap-3 flex justify-center items-center '>
                        <a href='#'><FacebookOutlinedIcon color='primary' className='text-3xl' /></a>
                        <a href='#'><TwitterIcon color='primary' className='text-3xl' /></a>
                        <a href='#'><LinkedInIcon color='primary' className='text-3xl' /></a>
                        <a href='#'><InstagramIcon color='primary' className='text-3xl' /></a>
                    </div>
                </div>
            </SInner>

            <SBottom >
                <div className='col-span-1'></div>
                <CSblackFooter className='col-span-8 flex items-center justify-center lg:ml-16 text-center'>© 2022  All Rights Reserved. Company Registration Number: 01254661100</CSblackFooter>
                <div className='col-span-3 flex lg:justify-end lg:w-[35%] w-[50%] lg:ml-[66%] mb-2'>

                    <a href='https://xiots.io/' target='blank'><img className='cursor-pointer ml-auto ' src='/assets/images/Xiots.png' style={{ width: '100%' }} /></a>
                </div>
            </SBottom>
        </SContainer>
    )
}

export default Footer