import React, { useState } from "react";
import {
    SContainer,
    SExpandedNav,
    SDrawer,
    SCrossDrawerDiv,
    SMenuIconDiv,
    SHeaderInner,
    SBookNowConatiner,
} from "../../styles/headerStyle";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { CSheading, CSsubHeading, CSHeaderTitles } from "@styles/common";
import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "next/router";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DropDown from "@common/DropDown/DropDown";
import Link from "next/link";


const navItem = [
    ["HOME", "/"],
    // ["BUSINESS", "/business"],
    ["SERVICES", "/services"],
    ["DRIVE WITH US", "/drive-with-us"],
    ["AIRPORT TRANSFERS", "/services/airport-transfer"],
    ["CONTACT US", "/contact-us"],
    ["ABOUT", "/about"],
];
const ServiceDropDownData = [
    ["Minibus", "/services/miniBus"],
    ["Airport Transfers", "/services/airport-transfer"],
    ["Executive", "/services/max-executive"],
    ["Service Levels", "/services/levels"],
];
function Header() {

    const router = useRouter();
    const [drawerFlag, setdrawerFlag] = useState(false);
    return (
        <SContainer>
            <Link href={'/'}>
                <a>
                    <img src="/assets/images/logo.png" />
                </a>
            </Link>
            <div>
                <SMenuIconDiv>
                    <MenuIcon
                        onClick={() => {
                            setdrawerFlag(true);
                        }}
                    />
                </SMenuIconDiv>

                <Drawer
                    anchor={"right"}
                    open={drawerFlag}
                    onClose={() => {
                        setdrawerFlag(false);
                    }}
                    PaperProps={{
                        elevation: 0,
                        style: { backgroundColor: "transparent" },
                        square: false,
                    }}
                    sx={{
                        ".MuiBackdrop-root ": { backgroundColor: "rgba(255,255,255,0.5)" },
                    }}
                >
                    <SDrawer>
                        <SCrossDrawerDiv>
                            <CloseIcon
                                onClick={() => {
                                    setdrawerFlag(false);
                                }}
                            />
                        </SCrossDrawerDiv>
                        {navItem.map(([title, link], index) => (
                            title === 'SERVICES' ?
                                <Accordion DisableGutters
                                    elevation={0}
                                    sx={{
                                        '&:before': {
                                            Display: 'none',
                                        }
                                    }}>
                                    <AccordionSummary
                                        sx={{ px: 0, py: 0, m: 0, '.MuiAccordionSummary-contentGutters': { py: 0, my: 0 } }}
                                        expandIcon={<ExpandMoreIcon />}

                                    >
                                        <CSheading>Services</CSheading>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        {ServiceDropDownData.map(([title, link], index) => (
                                            <CSheading key={index} className='ml-4' onClick={() => { setdrawerFlag(false); router.push(link) }}>{title}</CSheading>
                                        ))}
                                    </AccordionDetails>
                                </Accordion> :
                                <CSheading
                                    key={index}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        router.push(link);
                                        setdrawerFlag(false);
                                    }}
                                >
                                    {title}
                                </CSheading>
                        ))}
                        <CSheading
                            className="cursor-pointer "
                            onClick={() => {
                                router.push('/bookOnline');
                                setdrawerFlag(false);
                            }}>BOOK NOW</CSheading>
                    </SDrawer>
                </Drawer>
            </div>
            <SExpandedNav>
                {navItem.map(([title, link], index) => (
                    <SHeaderInner key={index}>
                        {title === 'SERVICES' ?
                            <DropDown title='SERVICES' data={ServiceDropDownData} />
                            : <CSHeaderTitles
                                key={index}
                                className="cursor-pointer mx-6 "

                                onClick={() => {
                                    title === 'SERVICES' ? null : router.push(link);

                                }}
                            >
                                {title}
                            </CSHeaderTitles>}
                    </SHeaderInner>
                ))}

                <SBookNowConatiner onClick={() => { router.push('/bookOnline') }}>
                    <CSsubHeading>BOOK NOW</CSsubHeading>
                </SBookNowConatiner>
            </SExpandedNav>
        </SContainer>
    );
}

export default Header;
