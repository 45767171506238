import tw, { styled } from 'twin.macro'
export { SContainer, SInner, SBottom }

const SContainer = styled.div`
${tw`text-xl md:text-5xl bg-primary/40 px-2 drop-shadow-2xl border-0 border-black`}
    img{
        ${tw`w-20`}
    }
    


`
const SInner = styled.div`
${tw`flex lg:flex-row lg:justify-between flex-col`}
`
const SBottom = styled.div`
${tw` text-center flex flex-col justify-center items-center lg:grid lg:grid-cols-12 mt-20 lg:mt-0 `}
`

